<template>
    <div class="footer">
        <p class="footer_text">
            ©IEP 2020
        </p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>

.footer {
   background: rgba(0, 0, 0, 0.8);
   height: 3em;
   display: flex;
   align-content: center;
}

.footer_text {
    color:white;
    margin: auto;
}
</style>